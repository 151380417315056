export default function () {
  function camelize (string: string): string {
    // Split the string at all dash characters
    return (
      string
        .replace('_', '-')
        .split('-')
        // Convert first char to upper case for each word
        .map(a => a[0].toUpperCase() + a.substring(1))
        // Join all the strings back together
        .join('')
    )
  }

  return {
    camelize
  }
}
