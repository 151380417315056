export default function () {
  const { camelize } = useCamelize()
  function getComponentName (name: string, prefix: string = ''): string {
    return `Block${prefix}${camelize(name)}`
  }
  function getBlockComponent (name: string, prefix: string = '') {
    const componentName = getComponentName(name, prefix)
    const component = resolveComponent(componentName)
    return component
  }

  return {
    getBlockComponent
  }
}
